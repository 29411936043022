@import '../base/variables.scss';

.HeaderStrip {
    padding-top: 127px;
    ul {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 930px;
        li {
            padding: 15px 0;
            a {
                svg {
                    color: $primary;
                }
                span {
                    position: relative;
                    &:after {    
                        background: none repeat scroll 0 0 transparent;
                        bottom: -2px;
                        content: "";
                        display: block;
                        height: 1px;
                        left: 50%;
                        position: absolute;
                        background: $secondary;
                        transition: width 0.3s ease 0s, left 0.3s ease 0s;
                        width: 0;
                    }
                    
                }
                &:hover {
                    cursor: pointer;
                    svg {
                        color: $secondary;
                    }
                    span {
                        color: $secondary;
                        &:after { 
                            width: 100%; 
                            left: 0; 
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .HeaderStrip {
        padding-top: 0px;
        ul {
            margin: 0 40px;
            flex-wrap: wrap;
            justify-content: center;
            li {
                margin: 0 20px;
                a {
                    font-size: 18px;
                    span {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .HeaderStrip {
        ul {
            margin: 0;
            padding: 8px 0;
            li {
                padding: 10px 0;
            }
        }
    }
}

@media (max-width: 479px) {
    .HeaderStrip {
        ul {
            padding: 6px 0;
            li {
                padding: 8px 0;
                a {
                    span {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

// @media (max-width: 820px) {
//     .HeaderStrip {
//         ul {
//             flex-direction: column;
//             li {
//                 padding: 10px 0;
//             }
//         }
//     }
// }