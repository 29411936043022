@import '../base/variables.scss';

.servicesStrip {
    background-color: $primary;
    ul {
        padding: 60px 0;
        display: flex;
        justify-content: space-between;
        li {
            color: #fff;
            margin: 0 20px;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
            svg {
                font-size: 100px;
            }
            h3 {
                margin: 15px 0 3px;
            }
            span {
                font-family: $secondaryFont;
                font-size: 20px;
                color: #fff;
            }
        }
    }
}

@media (max-width: 991px) {
    .servicesStrip {
        ul {
            flex-wrap: wrap;
            padding: 80px 0 90px;
            li {
                margin: 0 1%;
                width: 49%;
                &:first-child {
                    margin-bottom: 60px;
                }
                &:nth-child(2n) {
                    margin-right: 0;
                }
                &:nth-child(3n) { 
                    margin-left: 0;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .servicesStrip {
        ul {
            li {
                width: 100%;
                margin-bottom: 60px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media (max-width: 479px) {
    .servicesStrip {
        ul {
            padding: 50px 0 65px;
            li {
                margin-bottom: 40px;
                &:first-child {
                    margin-bottom: 40px;
                }
                svg {
                    font-size: 68px;
                }
                span {
                    font-size: 16px;
                }
            }
        }
    }
}