@import './variables.scss';

@mixin button($borderColour: $primary, $backColour: #fff, $backHoverColour: $primary, $textColour: $primary, $textHoverColour: #fff) {
    background-color: $backColour;
    border: 3px solid $borderColour;
    padding: 15px 40px;
    border-radius: 44px;
    font-size: 32px;
    font-family: $primaryFont;
    color: $textColour;
    font-weight: 600;
    @include backgroundColourTransition();
    @include colourTransition();
    &:hover {
        cursor: pointer;
        background-color: $backHoverColour;
        color: $textHoverColour;
    }
    @media (max-width: 479px) {
        font-size: 26px;
    }
}

@mixin backgroundColourTransition {
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;   
}

@mixin colourTransition {
    -webkit-transition: color 100ms linear;
    -ms-transition: color 100ms linear;
    transition: color 100ms linear;   
}

@mixin input {
    border-radius: 10px;
    border: none;
    box-sizing: border-box;
    padding: 15px;
    margin: 10px 0;
    color: $text_colour;
    -webkit-appearance: none;
    background-color: #fff;
    font-family: $secondaryFont;
    font-size: 20px;
    &:focus, &:focus-visible, &:focus-within {
        outline-color: $tertiary_background;
    }
    &::placeholder {
        color: $placeholder_colour;
    }
    &:-webkit-autofill, &:-webkit-autofill:focus {
        -webkit-box-shadow:0 0 0 50px $tertiary inset;
        -webkit-text-fill-color: $text_colour;
    }
    @media (max-width: 479px) {
        padding: 12px;
        margin: 8px 0;
    }
}

@mixin dropShadow($colour: #000, $x: 5px, $y: 5px, $blur: 20px, $spread: 0px) {
    -webkit-box-shadow: $x $y $blur $spread $colour;
    -moz-box-shadow: $x $y $blur $spread $colour;
    box-shadow: $x $y $blur $spread $colour;
}
