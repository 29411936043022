@import '../base/variables.scss';
@import '../base/mixins.scss';

.formWrap {
    background-color: $secondary;
    border-radius: 20px;
    padding: 60px;
    max-width: 1000px;
    margin: 0 auto 27px;
    position: relative !important;
    z-index: 4;
    @include dropShadow($tertiary_background);
    label {
        display: none;
    }
    input {
        @include input();
        width: 49%;
        box-sizing: border-box;
        margin-right: 2%;
        &:nth-child(4n) {
            margin-right: 0;
        }
    }
    textarea {
        @include input();
        width: 100%;
        height: 200px;
    }
    h2 {
        color: #fff;
        text-align: center;
        margin: 15px 0 35px 0;
    }
    span {
        text-align: left;
        color: $text_colour;
        display: block;
        margin: 10px 0;
        svg {
            font-size: 42px;
            vertical-align: middle;
            margin-right: 8px;
            color: $secondary;
        }
    }
    button {
        @include button($primary, $primary, #fff, #fff, $primary);
        width: 100%;
        margin-top: 20px;
        &:disabled,
        &[disabled] {
            cursor: default;
            border-color: $secondary;
            background-color: $secondary;
            color: #fff;
        }
    }
    .alertBox {
        position: absolute;
        bottom: 5px;
        p {
            color: #fff;
        }
    }

    .successBox {
        margin-right: 0;
        background-color: $secondary;
        border-radius: 44px;
        transition: width 2s;
        min-height: 440px;
        width: 100%;
        h2 {
            color: #fff;
            width: 100%;
        }
        p {
            color: #fff;
            width: 100%;
            text-align: center;
        }
    }
}

@media (max-width: 1550px) {   
    .formWrap {
        max-width: 800px;
    }
}

@media (max-width: 991px) {
    .formWrap {
        max-width: 100%;
        border-radius: 0;
        margin-bottom: 0;
        .successBox {
            min-height: 10px;
            height: auto;
        }
    }
}

@media (max-width: 767px) {
    .formWrap {
        padding: 60px 40px;
        input {
            width: 100%;
        }
    }
}

@media (max-width: 479px) {
    .formWrap {
        padding: 40px 20px 60px;
        margin-bottom: 60px;
    }
}