@import '../base/variables.scss';
@import '../base/mixins.scss';

.introContainer {
    background-color: $tertiary;
    p {
        text-align: left;
    }
    h2 {
        word-wrap: normal;
        width: 400px;
    }
    button.button {
        @include button();
        display: block;
        width: 280px;
        margin-top: 50px;
        @include dropShadow($tertiary_background);
    }
}
.split {
    display: flex;
    justify-content: space-between;
    padding: 90px 0;
    > div:first-of-type {
        margin-right: 160px;
    }
}


@media (max-width: 1850px) {
    .split {
        > div:first-of-type {
            margin-right: 60px;
        }
     }
}

@media (max-width: 1200px) {
    .split {
       flex-direction: column; 
       > div:first-of-type {
           margin-bottom: 120px;
       }
    }
}

@media (max-width: 991px) {
    .split {
        padding-bottom: 0;
        > div:first-of-type {
            margin: 0 40px 100px;
        }
    }
}

@media (max-width: 479px) {
    .split {
        padding-top: 50px;
        > div:first-of-type {
            margin: 0 20px 120px;
            margin-bottom: 60px;
            p {
                text-align: center;
            }
            h2 {
                width: 100%;
                text-align: center;
            }
            button {
                margin: 50px auto 0;
                text-align: center;
            }
        }
    }
}