$primaryFont: "houschka-pro", sans-serif;
$secondaryFont: "Segoe UI", sans-serif;

$primary: #783B98;
$secondary: #944CBA;
$secondary_background: #D1E9F0;
$tertiary_background: #E3B9FB;
$lightbox_background: rgba(58, 37, 69, 0.9);
$tertiary: #ECDCF5;
$placeholder_colour: #905bac;
$text_colour: #412550;