@import '../base/variables.scss';
@import '../base/mixins.scss';

.housekeepingContainer {
    padding: 0 0 120px;
    display: flex;
    justify-content: space-between;
    > p {
        margin: 0;
        align-self: center;
    }
    img {
        border-radius: 20px;
        max-height: 550px;
        @include dropShadow($tertiary_background);
    }
    p {
        text-align: left;
    }
    > div {
        background-color: $secondary;
        padding: 50px 60px;
        border-radius: 20px;
        min-width: 700px;
        @include dropShadow($tertiary_background);
        h2 {
            color: #fff;
        }
        > p {
            text-align: left;
            padding-bottom: 20px;
            color: #fff;
        }
        ul {
            margin-left: 20px;
            li {
                position: relative;
                text-align: left;
                &:before {
                    content: ".";
                    font-family: $secondaryFont;
                    font-size: 36px;
                    position: absolute;
                    top: -23px;
                    left: 0;
                    color: $tertiary;
                }
                p {
                    color: #fff;
                    text-indent: 16px;
                }
            }
        }
    }
}

@media (max-width: 1850px) {
    .housekeepingContainer {
        img {
            max-height: 440px;
        }
        > div {
            padding: 50px 50px;
            min-width: 500px;
        }
    }
}

@media (max-width: 1550px) {
    .housekeepingContainer {
        img {
            max-height: 320px;
        }
        > div {
            padding: 30px 40px;
            min-width: 300px;
        }
    }
}

@media (max-width: 1200px) {
    .housekeepingContainer {
        flex-direction: column-reverse;
        img {
            margin-top: 60px;
            max-height: 450px;
        }
        > div {
            padding: 50px 50px;
        }
    }
}

@media (max-width: 991px) {
    .housekeepingContainer {
        padding-bottom: 0;
        img {
            max-height: 450px;
            margin-bottom: 60px;
        }
        > div {
            border-radius: 0;
            padding: 80px 50px;
        }
    }
}

@media (max-width: 768px) {
    .housekeepingContainer {
        img {
            width: 100%;
        }
        > div {
        }
    }
}

@media (max-width: 479px) {
    .housekeepingContainer {
        img {
            margin-top: 0;
            border-radius: 0;
            margin-bottom: 40px;
            box-shadow: none;
        }
        > div {
            box-shadow: none;
            padding: 50px 20px;
            > p {
                padding-bottom: 10px;
            }
        }
    }
}