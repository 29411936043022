@import './variables.scss';

footer {
    border-top: 60px solid $secondary;
    background-color: $primary;
    color: white; 
    overflow: hidden;
    // height: 270px;
    margin-top: 60px;
    width: 100%;
    .footerInner {
        margin: 0 auto;
        padding: 30px 0 60px;
        box-sizing: border-box;
        width: 1700px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .links {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 900px;
            section {
                margin-right: 45px;    
                flex: 1 1;
                h3 {
                    text-align: left;
                    margin-bottom: 15px;
                }
                ul {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    li {
                        text-align: left;
                        span {
                            font-size: 20px;
                        }
                    }
                    &.connect {
                        li {
                            a {
                                display: block;
                                width: 300px;
                                span {
                                    position: relative;
                                    &:after {    
                                        background: none repeat scroll 0 0 transparent;
                                        bottom: -2px;
                                        content: "";
                                        display: block;
                                        height: 1px;
                                        left: 50%;
                                        position: absolute;
                                        background: #fff;
                                        transition: width 0.3s ease 0s, left 0.3s ease 0s;
                                        width: 0;
                                    }
                                }
                                &:hover {
                                    cursor: pointer;
                                    span {
                                        &:after { 
                                            width: 100%; 
                                            left: 0; 
                                        }
                                    }
                                }
                            }
                        }
                    }
                    li {
                        padding: 8px 0;
                        flex: 1 1;
                        a {
                            font-size: 20px;
                        }
                    }
                    &.navigation {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: flex-start;
                        flex-wrap: wrap;
                        column-gap: 20px;
                        height: 130px;
                        width: 250px;
                        li {
                            padding: 7px 0;
                            flex: 1 1;
                            button {
                                font-size: 20px;
                                background: none;
                                border: none;
                                color: #fff;
                                font-family: $secondaryFont;
                                padding: 0;
                                cursor: pointer;
                                position: relative;
                                &:after {    
                                    background: none repeat scroll 0 0 transparent;
                                    bottom: -2px;
                                    content: "";
                                    display: block;
                                    height: 1px;
                                    left: 50%;
                                    position: absolute;
                                    background: #fff;
                                    transition: width 0.3s ease 0s, left 0.3s ease 0s;
                                    width: 0;
                                }
                                &:hover {
                                    cursor: pointer;
                                    &:after { 
                                        width: 100%; 
                                        left: 0; 
                                    }
                                }
                            }
                        }
                    }
                    &.opening {
                        li {
                            a {
                                display: block;
                                width: 300px;
                                span {
                                    position: relative;
                                    &:after {    
                                        background: none repeat scroll 0 0 transparent;
                                        bottom: -2px;
                                        content: "";
                                        display: block;
                                        height: 1px;
                                        left: 50%;
                                        position: absolute;
                                        background: #fff;
                                        transition: width 0.3s ease 0s, left 0.3s ease 0s;
                                        width: 0;
                                    }
                                }
                                &:hover {
                                    cursor: pointer;
                                    span {
                                        &:after { 
                                            width: 100%; 
                                            left: 0; 
                                        }
                                    }
                                }
                            }
                        } 
                    }
                }
                span, a {
                    text-align: left;
                    color: #fff;
                }
            }
        }
        .info {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            p {
                color: #fff;
                margin: 0;
                // font-size: 20px;
                font-weight: 400;
            }
            svg {
                width: 230px;
                fill: #fff;
                margin-top: 32px;
                margin-bottom: 20px;
                tspan {
                    font-family: houschka-pro, sans-serif;
                    font-weight: 600;
                }
            }
        }
    }
}


@media (max-width: 1850px) {
    footer .footerInner {
        width: 1300px;
    }
}


@media (max-width: 1550px) {
    footer .footerInner {
        width: 1100px;
    }
}

@media (max-width: 1200px) {
    footer {
        .footerInner {
            flex-direction: column;
            width: 900px;
            .info {
                margin-top: 30px;
                align-items: center;
            }
            .links {
                width: 900px;
            }
        }
    }
}

@media (max-width: 991px) {
    footer {
        .footerInner {
            width: calc(100% - 100px);
            flex-wrap: wrap;
            margin: 0 50px;
            .links {
                width: auto;
                flex-direction: column;
                section {
                    flex: none;
                    width: auto;
                    margin-right: 0;
                    h3 {
                        text-align: center;
                    }
                    ul.connect, ul, ul.opening {
                        width: 100%;
                        li {
                            width: 100%;
                            a {
                                width: 100%;
                                text-align: center;
                            }
                            > span {
                                display: block;
                                width: 100%;
                                text-align: center;
                            }
                        }
                        &.navigation {
                            max-width: 440px;
                            margin: 0 auto;
                            li {
                                width: 50%;
                                button {
                                    margin: 0 auto;
                                    display: block;
                                }
                            }
                        }
                    }
                    &:nth-child(3n) {
                        page-break-after: always; /* CSS 2.1 syntax */
                        break-after: always;
                        wrap-after: flex;
                    }
                }
            }
        }
    }
}

// @media (max-width: 767px) { 
// }

@media (max-width: 479px) {
    footer {
        margin-top: 0;
        .footerInner {
            width: calc(100% - 40px);
            margin: 0 20px;
            .info svg {
                width: 170px;
            }
            .links {
                section {
                    ul {
                        &.navigation li button {
                            font-size: 18px;
                        }
                        &.connect li span {
                            font-size: 18px;
                        }
                        li span {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    } 
}