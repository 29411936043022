@import '../base/mixins.scss';

.aboutContainer {
    background-color: #fff;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 100px 0 90px;
    p {
        text-align: left;
    }
    img {
        border-radius: 20px;
        margin-left: 60px;
        height: 400px;
        @include dropShadow($tertiary_background);
    }
    .page {
        display: flex;
    }
}

@media (max-width: 1550px) {
    .aboutContainer {
        img {
            height: 320px;
        }
    }
}

@media (max-width: 1200px) {
    .aboutContainer {
        padding-top: 90px;
        flex-direction: column;
        img {
            height: 400px;
            margin: 0;
        }
    }
}

@media (max-width: 991px) {
    .aboutContainer {
        margin: 0 40px;
        padding-top: 80px;
        img {
            height: 350px;
        }
    }
}

@media (max-width: 767px) {
    .aboutContainer {
        > p:first-of-type {
            img {
                height: auto;
                width: 100%;
            }
        }
    }
}

@media (max-width: 479px) {
    .aboutContainer {
        margin: 0;
        padding-top: 50px;
        padding-bottom: 0;
        > div {
            margin: 0 20px;
            padding-bottom: 30px;
        }
        > p:first-of-type {
            margin-bottom: -7px;
            img {
                box-shadow: none;
                border-radius: 0;
            }
        }
    }
}