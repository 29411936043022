@import '../base/variables.scss';

.contactContainer {
    background-color: $tertiary;
    position: relative;
    overflow: hidden;
    margin-bottom: 200px;
    padding-top: 160px;
    img {
        position: absolute;
        top: 90px;
        z-index: 1;
        left: -200px;
        pointer-events: none;
    }
}

@media (max-width: 991px) {
    .contactContainer {
        padding-top: 0px;
        margin-bottom: 0px;
        img {
            display: none;
        }
    }
}

@media (max-width: 479px) {
    .contactContainer {
        margin-bottom: 0px;
    }
}