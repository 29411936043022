@import '../base/variables.scss';

.testimonialContainer {
    padding: 60px 0 80px;
    p {
        text-align: left;
    }
}

.testimonials {
    svg {
        color: $primary;
    }
    .testimonialSlick {
        padding: 30px 60px;
        .starEmpty {
            font-size: 30px;
            color: #fff;
            stroke: #000;
            stroke-width: 5;
            margin-right: 5px;
        }
        .star {
            font-size: 30px;
            stroke: #000;
            stroke-width: 5;
            margin-right: 5px;
            color: yellow;
        }
        .testimonial {
            display: flex !important;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            margin: 0 40px;
            p {
                text-align: left;
                margin: 10px 0;
                white-space: normal;
            }
            strong {
                color: $primary;
            }
            span {
                font-size: 32px;
                color: $primary;
                text-align: left;
                font-weight: 500;
                margin: 20px 0;
            }
        }
    }
}

@media (max-width: 991px) {
    .testimonialContainer {
        padding: 60px 40px 80px;
    }
    .testimonials {
        .testimonialSlick {
            .testimonial {
                margin: 0 10px;
            }
        }
    }
}

@media (max-width: 479px) {
    .testimonialContainer {
        padding: 0px 20px 0px;
    }
    .testimonials {
        .testimonialSlick {
            padding: 0px 0px 40px;
            .testimonial {
                margin: 0 10px;
                p {
                    overflow-wrap: anywhere;
                }
            }
        }
    }
}