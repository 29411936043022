@import '../base/variables.scss';

.galleryContainer {
    background-color: $primary;
    padding: 80px 0 330px;
    flex-grow: 1;
    display: flex;
    position: relative;
    flex-direction: column;
    overflow: hidden;
    .textBox {
        p {
            color: #fff;
        }
    }
    .gallerySlick {
        width: 100%;
        padding: 30px 60px;
        div {
            height: 100%;
            height: 360px;
            .item {
                background-position: center;
                background-size: cover;
                outline: none;
                display: block;
                background-repeat: no-repeat;
                border-radius: 10px;
                margin: 0 20px;
                cursor: zoom-in;
            }
        }
    }
    h2 {
        color: #fff;
        text-align: center;
    }
    > img {
        position: absolute;
        top: 230px;
        left: -200px;
        pointer-events: none;
    }
}

@media (max-width: 1550px) {
    .galleryContainer {
        padding: 80px 0 300px;
        .gallerySlick {
            div {
                height: 300px;
            }
        }
        > img {
            top: 140px;
        }
    }
}

@media (max-width: 1200px) {
    .galleryContainer {
        padding: 80px 0 200px;
        .gallerySlick {
            div {
                height: 250px;
            }
        }
        > img {
            top: 20px;
        }
    }
}

@media (max-width: 991px) {
    .galleryContainer {
        padding: 80px 40px 220px;
        .gallerySlick {
            padding: 30px 40px;
            div {
                height: 230px;
            }
        }
        > img {
            top: 20px;
        }
    }
}

@media (max-width: 768px) {
    
}

@media (max-width: 479px) {
    .galleryContainer {
        display: none;
        // padding: 60px 20px 160px;
        // .gallerySlick {
        //     padding: 30px 40px;
        //     div {
        //         height: 250px;
        //     }
        // }
        // > img {
        //     top: -40px;
        // }
    }
}