@import './components/base/variables.scss';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
  }
}

p, span, a {
  color: $text_colour;
  font-family: $secondaryFont;
  font-size: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  font-family: $primaryFont;
  color: $primary;
}

h2 {
  font-family: $primaryFont;
  color: $primary;
  text-align: left;
  font-size: 44px;
  font-weight: 600;
  line-height: 54px;
  margin-top: 0;
  margin-bottom: 30px;
}

h3 {
  font-family: $primaryFont;
  font-size: 32px;
}

a {
  font-family: $secondaryFont;
  text-decoration: none;
}

.page {
  margin: 0 auto;
  box-sizing: border-box;
  width: 1700px; 
}

@media (max-width: 1850px) {
  .page {
      width: 1300px;
      margin: 0 auto;
  }
}

@media (max-width: 1550px) {
  .page {
      width: 1100px;
  }
}
  
@media (max-width: 1200px) {
  .page {
      width: 900px; 
  }
}

@media (max-width: 991px) {
 .page {
      width: 100%; 
  } 
  p {
      font-size: 18px;
      line-height: 26px;
  }
}
@media (max-width: 767px) {
  .page {
      width: 100%; 
  } 
  .page.full {
      width: 100%;
  }
  .main.full {
      padding: 0;
  }
}
@media (max-width: 479px) {
  .main {
      padding: 0 20px;
  }
  .page {
      width: 100%; 
      overflow: hidden;
  } 
  h2 {
      text-align: left;
      font-size: 36px;
      line-height: 46px;
      margin-bottom: 20px;
  }
  h3 {
    font-size: 28px;
  }
  .content .page {
      padding-bottom: 0;
  }
}

.ReactModalPortal {
  .ril__outer {
    background-color: $lightbox_background;
  }
  img.ril-image-current {
    border-radius: 20px;
    border: 10px solid $tertiary;
  }
  .ril__toolbar {
    background-color: $lightbox_background;
    padding-bottom: 5px;
  }
  .ril-image, .ril-image-prev, .ril-image-next {
    pointer-events: none;
  }
}

.grecaptcha-badge {
  z-index: 20;
}

.slick-dots {
  position: absolute;
  right: 20px;
  bottom: 20px;
  li {
    float: left;
    button {
      font-size: 0;
      border: none;
      border-radius: 50%;
      background-color: lighten($color: $secondary, $amount: 30);
      margin-left: 10px;
      height: 12px;
      width: 12px;
      &:hover {
        cursor: pointer;
        background-color: lighten($color: $secondary, $amount: 10);
      }
    }
    &.slick-active {
      button {
        background-color: darken($color: $secondary, $amount: 5);
      }
    }
  }
}
.slick-prev {
  position: absolute;
  color: #fff;
  top: calc(50% - 15px);
  left: 0px;
  font-size: 40px;
  cursor: pointer;
}

.slick-next {
  position: absolute;
  color: #fff;
  top: calc(50% - 15px);
  right: 0px;
  font-size: 40px;
  cursor: pointer;
}

// @media (max-width: 479px) {
//   .slick-next, .slick-prev {
//     display: none !important;
//   }
// }

.slick-list,.slick-slider,.slick-track{position:relative;display:block}.slick-loading .slick-slide,.slick-loading .slick-track{visibility:hidden}.slick-slider{box-sizing:border-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent}.slick-list{overflow:hidden;margin:0;padding:0}.slick-list:focus{outline:0}.slick-list.dragging{cursor:pointer;cursor:hand}.slick-slider .slick-list,.slick-slider .slick-track{-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);-o-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}.slick-track{top:0;left:0}.slick-track:after,.slick-track:before{display:table;content:''}.slick-track:after{clear:both}.slick-slide{display:none;float:left;height:100%;min-height:1px}[dir=rtl] .slick-slide{float:right}.slick-slide img{display:block}.slick-slide.slick-loading img{display:none}.slick-slide.dragging img{pointer-events:none}.slick-initialized .slick-slide{display:block}.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}.slick-arrow.slick-hidden{display:none}/*# sourceMappingURL=slick.min.css.map */