@import './variables.scss';

header {
    background-color: $primary;
    position: fixed;
    width: 100%;     
    z-index: 999;
    .mainHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .mobileButton {
            display: none;
        }
        h1 {
            margin: 0;
            a {
                display: flex;    
                padding: 12px 0px 16px;
                svg {
                    width: 250px;
                    fill: #fff;
                    tspan {
                        font-family: houschka-pro, sans-serif;
                    }
                }
            }
        }
        ul.nav {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            margin: 0;
            padding: 0;
            li {
                list-style-type: none;
                button {
                    color: #fff;
                    font-family: $primaryFont;
                    padding: 51px 20px;
                    display: block;
                    text-decoration: none;
                    font-weight: 500;
                    font-size: 20px;
                    border: none;
                    background: none;
                    &:hover {
                        cursor: pointer;
                        background-color: $secondary;
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    header .mainHeader {
        ul.nav li button {
            padding: 51px 17px;
            font-size: 19px;
        }
        h1 a {
            padding: 19px 0px 19px;
            svg {
                width: 210px;
            }
        }
    }
}

@media (max-width: 991px) {
    header {
        position: static;
        .mainHeader {
            flex-wrap: wrap;
            > h1, > span {
                margin: 0 40px;
            }
            .mobileButton {
                display: block;
                cursor: pointer;
                align-self: center;
                svg {
                    color: #fff;
                    font-size: 36px;
                }
            }
            ul.nav {
                overflow: hidden;
                max-height: 0;
                height: auto;
                width: 100%;
                transition: max-height 0.25s ease-out;
                background-color: $tertiary_background;
                display: block;
                li {
                    border-bottom: 1px solid $primary;
                    button {
                        color: $text_colour;
                        width: 100%;
                        font-size: 20px;
                        padding: 30px 0;
                        &:hover {
                            background-color: darken($tertiary_background, 10);
                        }
                    }
                }
                &.active {
                    max-height: 600px;
                    transition: max-height 0.25s ease-in;
                }
            }
            h1 a {
                padding: 19px 0px 25px;
                svg {
                    width: 200px;
                    text {
                    }
                }
            }
        }
    }
}

@media (max-width: 479px) {
    header .mainHeader {
        .mobileButton {
            margin: 0 25px;
            margin-left: 0;
            svg {
                font-size: 32px;
            }
        }
        h1 {
            margin: 0 20px;
            a {    
                padding: 16px 0px 18px;
                svg {
                    width: 170px;
                }
            }
        }
        ul.nav {
            li {
                button {
                    padding: 22px 0;
                }
                &.gallery {
                    display: none;
                }
            }
        }
    }
}